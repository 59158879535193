<template>
  <v-container>
    <v-row>
      <v-col>
        <span class="text-h4">Editar Turno Asignado</span>
      </v-col>
    </v-row>
    <v-divider> </v-divider>
    <br />
    <div v-if="turnoEmpleado">
      <TurnoAsignarForm
        :turnoEmpleado="turnoEmpleado"
        @empleado-changed="actualizarCalendario"
        @desde-changed="actualizarComienzoCalendario"
      ></TurnoAsignarForm>
    </div>
    <br />
    <div>
      <TurnosAsignadosCalendarioCard
        ref="calendar"
        :loading="this.calendarLoading"
        :asignaciones="this.empleadoTurnos"
        :start="comienzoCalendario"
      ></TurnosAsignadosCalendarioCard>
    </div>
  </v-container>
</template>
<script>
import TurnoAsignarForm from "../components/TurnoAsignarForm.vue";
import TurnosAsignadosCalendarioCard from "../components/TurnosAsignadosCalendarioCard.vue";
import PresentismoService from "@/services/PresentismoService.js";

export default {
  name: "TurnoAsignarEdit",
  components: {
    TurnoAsignarForm,
    TurnosAsignadosCalendarioCard,
  },
  data() {
    return {
      turnoEmpleado: null,
      empleadoTurnos: [],
      calendarLoading: false,
      comienzoCalendario: "",
    };
  },
  props: ["id"],
  created() {
    PresentismoService.getTurnoEmpleado(this.id)
      .then((response) => {
        this.turnoEmpleado = response.data;
        console.log("Turno empleado: " + this.turnoEmpleado);
        this.comienzoCalendario = this.turnoEmpleado.desde;
        this.actualizarCalendario(this.turnoEmpleado.empleado.id);
      })
      .catch((error) => console.log(error));
  },
  methods: {
    actualizarComienzoCalendario(event) {
      this.comienzoCalendario = event;
    },
    actualizarCalendario(event) {
      if (!event) {
        this.empleadoTurnos = [];
        return;
      }

      this.calendarLoading = true;
      PresentismoService.getTurnosEmpleados(event, 1)
        .then((response) => {
          this.empleadoTurnos = response.data;
          this.calendarLoading = false;
          // console.log("Turnos Empleado: " + this.empleadoTurnos);
          // this.$refs.calendar.asignacionesToEvents();
        })
        .catch((error) => {
          console.log(error);
          this.loadingSave = false;
        });
    },
  },
};
</script>
